import { format, parseISO } from 'date-fns';
import { bool, formats } from '@/config/ro';
import { parse as parseDuration } from 'iso8601-duration';
import { isString } from '@/lib/tools';

export const humanizeDuration = (duration: any = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const _duration = isString(duration) ? parseDuration(duration) : duration;

  return [
    ['years', 'an', 'ani'],
    ['months', 'lună', 'luni'],
    ['weeks', 'săptămână', 'săptămâni'],
    ['days', 'zi', 'zile'],
    ['hours', 'oră', 'ore'],
    ['minutes', 'minut', 'minute'],
    ['seconds', 'secundă', 'secunde'],
  ]
    .filter(([field]) => !!_duration[field])
    .map(([field, sg, pl]) => `${_duration[field]} ${_duration[field] === 1 ? sg : pl}`)
    .join(', ');
};

export const parseDate = (dateStr: any) => {
  const date = parseISO(dateStr);

  return isNaN(date.getTime()) ? null : date;
};

const _format = (f: any) => (dateStr: any) => {
  const date = dateStr instanceof Date ? dateStr : parseISO(dateStr);

  return date && !isNaN(date.getTime()) ? format(date, f) : '';
};

export const formatDateTime = _format(formats.dateTime);
export const formatDate = _format(formats.date);
export const formatDateAlt = _format(formats.dateAlt);

export const formatCurrency = (v: any) => v.toFixed(2);

export const boolean = (s: any) => (s ? bool.yes : bool.no);
export const nullable = (s: any, emptyText = '-') => (['', null, undefined].includes(s) ? emptyText : s);
